import { getCompanyOneById, putCompany, postCompany } from "@/api/company";
import { getUserByCompany, putUser, postUser } from "@/api/user";
import { getClientByCompany, putClient, postClient } from "@/api/client";
import { getItemByCompanyP, putItem, postItem } from "@/api/item";
import { checkUserPw, modifyPassword } from "@/api/user";
import {
  getClothestypeByCompany,
  putClothestype,
  postClothestype,
} from "@/api/clothestype";

import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import ExcelUploadDialog from "@/components/organisms/VDialog/ExcelUploadDialog";
import settingFormDialog from "@/components/organisms/VDialog/SettingFormDialog";
import inputSetting from "./inputSetting";
import printSetting from "./printSetting";
import paySetting from "./pays/index.vue";
import OrderStatus from "@/assets/data/status/OrderStatus";
import { mapGetters } from "vuex";
import { validatePassword } from "@/utils/validation";
import { postfile, getfileOneById, downloadFile, deletefile } from "@/api/file";

export default {
  components: {
    ExcelUploadDialog,
    settingFormDialog,
    SnackBar,
    LoadingSpinner,
    inputSetting,
    printSetting,
    paySetting,
  },
  data() {
    return {
      // common - editform
      editedItem: {},
      deletedItem: {},
      // setting - company

      // setting - user

      // itemClientList: [],
      userSearch: "",
      userHeaders: [
        {
          text: "등급",
          value: "grade",
          width: "10%",
          align: "center",
        },
        { text: "ID", value: "uuid", align: "center", width: "30%" },
        {
          text: "이름",
          value: "name",
          align: "center",
          width: "30%",
        },
        {
          text: "상태",
          value: "signStatus",
          align: "center",
          width: "30%",
        },
      ],
      dialog_user: false,
      // setting - client
      // clientList: [],
      clientFilter: "all",
      clientStatus: "confirm",
      statusType: [
        {
          value: "all",
          label: "전체",
        },
        {
          value: "confirm",
          label: "승인 확인",
        },
        {
          value: "delete",
          label: "삭제",
        },
      ],

      clientFilterItems: this.$store.state.client_kind,
      clientSearch: "",
      billClientSearch: "",
      clientHeaders: [
        {
          text: "",
          value: "check",
          width: "3%",
          align: "center",
        },
        {
          text: "구분",
          value: "company_type",
          width: "10%",
          align: "center",
        },
        {
          text: "계산서 발행처",
          value: "bill_client",
          width: "20%",
          align: "center",
        },

        { text: "회사명", value: "name", align: "center", width: "20%" },
        {
          text: "사업자번호",
          value: "business_num",
          align: "center",
          width: "10%",
        },
        { text: "대표명", value: "officer", align: "center", width: "10%" },
        { text: "전화번호", value: "tel", align: "center", width: "5%" },
        { text: "이메일", value: "email", align: "center", width: "10%" },
        { text: "상태", value: "status", align: "center", width: "5%" },
        { value: "edit", width: "10%", sortable: false },
      ],
      excelClientHeaders: [
        { text: "구분", value: "company_type", width: "3%" }, // 거래처 종류
        { text: "회사명구분", value: "type_postion", width: "3%" }, // 거래처 종류
        { text: "지점", value: "zone", width: "5%" }, // 거래처 종류
        { text: "회사명", value: "name_only", width: "10%" }, // 회사명
        { text: "계산서 발행처", value: "bill_client", width: "10%" }, // 거래처 종류
        {
          text: "사업자번호",
          value: "business_num",
          width: "10%",
        }, // 사업자 번호
        { text: "업종", value: "industry", width: "10%" }, // 업종
        { text: "대표", value: "officer", width: "10%" }, // 대표
        { text: "담당자", value: "liaison", width: "10%" }, // 연락 담당자
        { text: "전화번호", value: "tel", width: "7%" }, // 전화번호
        { text: "팩스번호", value: "fax", width: "7%" }, // 팩스번호
        { text: "이메일", value: "email", width: "7%" }, // 이메일
        { text: "주소", value: "address", width: "15%" }, // 주소
        { text: "", value: "modify", width: "5%" }, // 주소
      ],
      // viewTel: "",
      // viewBusinessNum: "",
      viewClientTel: "",
      viewClientBusinessNum: "",
      dialog_client: false,
      // setting - item
      // itemList: [],
      inspectStatusText: "검사포함",
      itemSearch: "",
      productionSearch: "",
      itemStatus: "confirm",
      itemHeaders: [
        { text: "", value: "check", align: "center", width: "3%" },
        { text: "아이템명", value: "name", align: "center", width: "20%" },
        { text: "가격", value: "unit_price", align: "center", width: "10%" },
        { text: "생산처", value: "production", align: "center", width: "20%" },
        {
          text: "생산처 Item No",
          value: "production_item",
          align: "center",
          width: "20%",
        },
        { text: "담당자", value: "liaison", align: "center", width: "10%" },
        { text: "상태", value: "status", align: "center", width: "10%" },
        { value: "edit", sortable: false, width: "10%" },
      ],
      excelItemHeaders: [
        { text: "아이템명", value: "name", width: "5%" },
        { text: "가격", value: "unit_price", width: "5%" },
        { text: "가격 단위", value: "currency_sign", width: "5%" },
        { text: "길이 단위", value: "unit_length", width: "5%" },
        { text: "검사 포함 여부", value: "inspect_status", width: "5%" },
        { text: "생산처 ", value: "production", width: "5%" },
        { text: "생산처 Item ", value: "production_item", width: "5%" },
        { text: "경사", value: "slope", width: "5%" },
        { text: "위사", value: "oblique", width: "5%" },
        { text: "사종", value: "variety", width: "5%" },
        { text: "폭", value: "width", width: "5%" },
        { text: "전체 폭", value: "full_width", width: "5%" },
        { text: "무게단위", value: "unit_width", width: "5%" },
        { text: "밀도", value: "density", width: "5%" },
        { text: "중량", value: "weight", width: "5%" },
        { text: "무게", value: "unit_weight", width: "5%" },
        { text: "후가공", value: "post_processing", width: "5%" },
        { text: "내용", value: "content", width: "5%" },
        { text: "담당자", value: "liaison", width: "5%" },
      ],
      dialog_item: false,
      // setting - ctype
      // ctypeList: [],
      ctypeSearch: "",
      ctypeStatus: "confirm",
      ctypeHeaders: [
        { text: "", value: "check", align: "center", width: "3%" },
        { text: "복종명", value: "name", align: "center", width: "77%" },
        { text: "상태", value: "status", align: "center", width: "10%" },
        { value: "edit", width: "10%", sortable: false },
      ],
      excelCtypeHeaders: [{ text: "이름", value: "name", width: "100%" }],
      dialog_ctype: false,
      OrderStatus,
      snackbar: false,
      snackbar_text: "",
      timeout: 1500,
      page: 1,
      pageInput: 1,
      pageCount: 0,
      itemsPerPage: 20,
      items: [
        {
          value: 10,
          label: "10개씩 보기",
        },
        {
          value: 20,
          label: "20개씩 보기",
        },
        {
          value: 30,
          label: "30개씩 보기",
        },
      ],
      status: [
        {
          value: "20",
          label: "요청",
        },
        {
          value: "30",
          label: "보류",
        },
        {
          value: "40",
          label: "활성화",
        },
        {
          value: "99",
          label: "정지",
        },
      ],
      file: null,

      logoTypeList: [
        {
          value: 1,
          label: "로고 이미지 / 로고 텍스트",
        },
        {
          value: 2,
          label: "로고 이미지",
        },
        {
          value: 3,
          label: "로고 텍스트",
        },
      ],

      existingPw: "", // 현재 비밀번호
      existingCheck: false, // 비밀번호 확인
      modifyPw: "", // 변경 비밀 번호
      checkPw: "", // 변경 비밀번호 확인

      existingHint: "현재 비밀번호를 입력 후 확인해주세요",

      viewType: "list",
      allChecked: { client: false, item: false, ctype: false },

      saveLogoImg: null,
      preview: "",
    };
  },
  computed: {
    ...mapGetters({
      clientList: "getClientList",
      userList: "getUserList",
      itemList: "getItemList",
      itemClientList: "getItemClientList",
      ctypeList: "getCtypeList",
      excelFormList: "getExcelFormList",
      itemExcelFormList: "getItemExcelFormList",
      ctypeExcelFormList: "getCtypeExcelFormList",
      navSelected: "getNavSelected",
      company: "getEditCompany",
      logoImg: "getLogoImg",
    }),
    formTitleUser() {
      return this.editedItem._id ? "Edit User" : "New User";
    },
    formTitleClient() {
      return this.editedItem._id ? "Edit Client" : "New Client";
    },
    formTitleItem() {
      return this.editedItem._id ? "Edit Item" : "New Item";
    },
    formTitleColor() {
      return this.editedItem._id ? "Edit Color" : "New Color";
    },
    formTitleCtype() {
      return this.editedItem._id ? "Edit Ctype" : "New Ctype";
    },
    isModifyPwBtn() {
      let isBtn = true;

      // 변경 비밀번호와 확인 비밀번호 같을 경우
      if (this.modifyPw && this.modifyPw === this.checkPw) isBtn = !isBtn;
      // 변경 비밀번호와 현재 비밀번호가 같을경우
      if (this.modifyPw === this.existingPw) isBtn = true;
      //특수, 숫자, 영뮨 포함 확인
      if (validatePassword(this.modifyPw)) isBtn = true;

      return isBtn;
    },

    modifyPwHint() {
      let hint = "";

      if (this.modifyPw) hint = "변경 비밀번호 확인 해주세요";
      if (validatePassword(this.modifyPw))
        hint = "비밀번호는 8~20자 이상 영문,숫자,특수문자형태로 입력해주세요";
      if (this.modifyPw && this.modifyPw === this.existingPw)
        hint = "현재 비밀번호와 동일합니다. 새로운 비밀번호를 입력해주세요";
      return hint;
    },

    checkPwHint() {
      let hint = "변경 될 비밀번호를 입력해주세요";
      if (this.checkPw && this.checkPw !== this.modifyPw)
        hint = "변경 비밀번호와 다릅니다. 다시 확인해주세요";
      if (this.checkPw && this.checkPw === this.modifyPw)
        hint = "변경될 비밀번호와 동일합니다.";
      return hint;
    },
    filterClientList() {
      let clientList = [...this.clientList].sort((a, b) => a.status - b.status);

      if (this.clientFilter !== "all")
        clientList = clientList.filter(
          (x) => x.company_type === this.clientFilter,
        );
      else clientList = [...this.clientList];
      if (this.billClientSearch)
        clientList = clientList.filter((x) => {
          if (x.bill_client)
            return x.bill_client.name.match(
              new RegExp(this.billClientSearch, "i"),
            );
        });
      if (this.clientSearch)
        clientList = clientList.filter((x) => {
          return x.name.match(new RegExp(this.clientSearch, "i"));
        });
      clientList = clientList.filter((x) => {
        if (this.clientStatus === "all") clientList = [...this.clientList];
        return this.clientStatus === "confirm"
          ? x.status === "40"
          : this.clientStatus === "delete"
          ? x.status === "99"
          : x;
      });
      return clientList;
    },
    filterItemList() {
      let itemList = [...this.itemList].sort((a, b) => a.status - b.status);

      if (this.itemSearch || this.productionSearch)
        itemList = itemList.filter((x) => {
          if (this.itemStatus === "all") itemList = [...this.itemList];

          return (
            x.name.match(new RegExp(this.itemSearch, "i")) &&
            x.production.name.match(new RegExp(this.productionSearch, "i"))
          );
        });
      itemList = itemList.filter((x) => {
        if (this.itemStatus === "all") itemList = [...this.itemList];
        return this.itemStatus === "confirm"
          ? x.status === "40"
          : this.itemStatus === "delete"
          ? x.status === "99"
          : x;
      });
      return itemList;
    },
    filterCtypeList() {
      let ctypeList = [...this.ctypeList].sort((a, b) => a.status - b.status);

      if (this.ctypeSearch)
        ctypeList = ctypeList.filter((x) => {
          if (this.ctypeStatus === "all") ctypeList = [...this.ctypeList];

          return x.name.match(new RegExp(this.ctypeSearch, "i"));
        });
      ctypeList = ctypeList.filter((x) => {
        if (this.ctypeStatus === "all") ctypeList = [...this.ctypeList];
        return this.ctypeStatus === "confirm"
          ? x.status === "40"
          : this.ctypeStatus === "delete"
          ? x.status === "99"
          : x;
      });
      return ctypeList;
    },
    filterUserList() {
      let userList = [...this.userList];
      // userList = userList.filter((x) => {
      //   if (this.ctypeStatus === "all") userList = [...this.userList];
      //   return this.ctypeStatus === "confirm"
      //     ? x.status === "40"
      //     : this.ctypeStatus === "delete"
      //     ? x.status === "99"
      //     : x;
      // });
      userList = userList.filter((x) => {
        // if (this.userStatus === "all") userList = [...this.userList];
        return x.name.match(new RegExp(this.userSearch, "i"));
      });
      return userList;
    },
  },
  methods: {
    // setting - company
    selectPage() {
      const num = parseInt(this.pageInput);
      this.page = isNaN(num) ? 0 : num;
      this.pageInput = this.page;
    },
    async saveCompanyForm() {
      if (!this.$store.state.viewBusinessNum) {
        this.snackbar_text = `사업자 번호를 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.company.name) {
        this.snackbar_text = `회사 명을 입력해주세요.`;
        this.snackbar = true;
        return false;
      }
      this.company.business_num = this.$store.state.viewBusinessNum;
      this.company.tel = this.$store.state.viewTel;
      this.company.id = this.$store.state.auth_company;

      await this.postCompany();
      if (this.saveLogoImg) this.saveLogo();

      this.snackbar_text = `Save Complete : ${this.company.id}`;
      this.snackbar = true;

      await this.$store.dispatch("GET_COMPANY");
    },

    async saveLogo() {
      if (this.logoImg) this.logoDelete();
      try {
        const result = await postfile(this.saveFormData(this.saveLogoImg));

        if (result.status !== 200) throw `error: ${result.status}`;

        this.saveLogoImg = null;
        await this.setPrivew();
      } catch (e) {
        console.log(e);
      }
    },

    async logoDelete() {
      try {
        const result = await deletefile(this.logoImg._id);

        if (result.status !== 200) throw `error: ${result.status}`;
      } catch (e) {
        console.log(e);
      }
    },

    saveFormData() {
      const formData = new FormData();
      formData.append("id", this.$store.state.auth_company);
      formData.append("type", this.saveLogoImg.type);
      formData.append("file", this.saveLogoImg);
      return formData;
    },

    changeLogo() {
      if (this.saveLogoImg) {
        const fileData = (data) => {
          this.preview = data;
        };
        const reader = new FileReader();

        reader.readAsDataURL(this.saveLogoImg);
        reader.addEventListener(
          "load",
          function () {
            fileData(reader.result);
          },
          false,
        );
      } else {
        this.preview = "";
        this.getLogoImg();
      }
    },

    // setting - user
    openNewUserForm() {
      this.editedItem = Object.assign({});
      this.dialog_user = true;
    },
    openEditUserForm(item) {
      this.editedItem = Object.assign({}, item);
      if (this.userList.indexOf(item) === -1)
        this.editedItem.company = this.$store.state.auth_company;
      this.dialog_user = true;
    },
    closeEditUserForm() {
      this.dialog_user = false;
      this.$nextTick(
        () => (this.editedItem = Object.assign({}, this.defaultItem)),
      );
    },

    // setting - client
    openNewClient() {
      this.$store.commit("setEditedItemForm");
      this.$store.state.editedItem.type = "Client";
      this.$store.state.editedItem.kind = 1;
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    openEditClientForm(item) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };
      this.editedItem.type = "Client";

      this.editedItem.company = this.$store.state.auth_company;
      this.editedItem.business_num
        ? (this.editedItem.business_num = this.getBusinessNumMask(
            this.editedItem.business_num,
          ))
        : "";

      this.editedItem.tel
        ? (this.editedItem.tel = this.getPhoneMask(this.editedItem.tel))
        : "";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    closeEditClientForm() {
      this.dialog_client = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveClientForm() {
      if (
        this.clientList.find((x) => !x._id && x.name === this.editedItem.name)
      ) {
        this.snackbar_text = "동일이름의 거래처가 존재합니다.";
        this.snackbar = true;
        return;
      }

      if (!this.editedItem.kind) {
        this.snackbar_text = `거래처 종류를 선택해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.editedItem.name) {
        this.snackbar_text = `회사명을 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      this.editedItem.status = "40";

      this.editedItem.business_num = this.viewClientBusinessNum.replace(
        /[^0-9]/g,
        "",
      );

      await this.postClient();
      // await this.getClientList();
      await this.$store.dispatch("SET_CLIENTLIST");

      this.snackbar_text = `Save Complete : ${this.editedItem.name}`;
      this.snackbar = true;
      this.closeEditClientForm();
    },
    // setting - item
    openNewItemForm() {
      this.editedItem = {
        currency_sign: "₩",
        unit_length: "y",
        unit_width: "in",
        unit_weight: "g/y",
        inspect_status: true,
      };
      this.editedItem.type = "Item";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    StatusCheck() {
      this.editedItem.inspect_status
        ? (this.inspectStatusText = "검사포함")
        : (this.inspectStatusText = "검사미포함");
    },
    openEditItemForm(item) {
      this.$store.commit("setEditedItemForm");
      this.editedItem = { ...item };
      this.editedItem.type = "Item";
      this.editedItem.company = this.$store.state.auth_company;

      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    closeEditItemForm() {
      this.dialog_item = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveItemForm() {
      if (!this.editedItem.name) {
        this.snackbar_text = `아이템명을 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.editedItem.production) {
        this.snackbar_text = `생산처를 선택해주세요.`;
        this.snackbar = true;
        return;
      }
      if (!this.editedItem.production_item) {
        this.snackbar_text = `아이템명(생산처)를 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      this.editedItem.status = "40";

      await this.postItem();
      await this.$store.dispatch("SET_ITEMLIST");

      this.snackbar_text = `Save Complete : ${this.editedItem.name}`;
      this.snackbar = true;
      this.closeEditItemForm();
    },
    excelUpload(type) {
      this.$store.dispatch("ExcelUpload", { type: type, file: this.file });
    },
    excelUploadDialogOpen(type) {
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "dialog_ExcelUpload",
      });
      this.$store.state.excelUploadType = type;
    },
    itemWidthCheck() {
      if (
        this.editedItem.width !== "" &&
        this.editedItem.full_width !== "" &&
        +this.editedItem.width > +this.editedItem.full_width
      ) {
        this.snackbar_text = `가용 폭은 전체 폭을 초과할 수 없습니다.`;
        this.snackbar = true;
        this.editedItem.width = "";
      }
    },
    // setting - color
    openNewColorForm() {
      this.editedItem = Object.assign({});
      this.dialog_color = true;
    },

    closeEditColorForm() {
      this.dialog_color = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveColorForm() {
      if (!this.editedItem.name) {
        this.snackbar_text = `color명을 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      await this.postColor();
      await this.getColorList();
      this.snackbar_text = `Save Complete : ${this.editedItem.name}`;
      this.snackbar = true;
      this.closeEditColorForm();
    },
    // setting - ctype
    openNewCtypeForm() {
      this.editedItem = Object.assign({});
      this.editedItem.type = "Ctype";
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    openEditCtypeForm(item) {
      this.editedItem = { ...item };
      this.editedItem.type = "Ctype";
      this.editedItem.company = this.$store.state.auth_company;
      this.$store.commit("setEditedItemForm", this.editedItem);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
    closeEditCtypeForm() {
      this.dialog_ctype = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async saveCtypeForm() {
      if (!this.editedItem.name) {
        this.snackbar_text = `복종명을 입력해주세요.`;
        this.snackbar = true;
        return;
      }
      await this.postCtype();
      await this.$store.dispatch("SET_CTYPELIST");
      this.snackbar_text = `Save Complete : ${this.editedItem.name}`;
      this.snackbar = true;
      this.closeEditCtypeForm();
    },
    // api
    async postCompany() {
      try {
        const result = this.company._id
          ? await putCompany(this.company)
          : await postCompany(this.company);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
    async postUser(user) {
      user.company = this.$store.state.auth_company;

      try {
        const result = user._id ? await putUser(user) : await postUser(user);

        if (result.status !== 200) throw `error : ${result.status}`;

        // await this.getUserList();
        await this.$store.dispatch("SET_USERLIST");
        this.snackbar_text = `Save Complete : ${user.uuid}`;
        this.snackbar = true;
      } catch (e) {
        alert(e);
      }
    },
    async postClient() {
      try {
        this.editedItem.company = this.$store.state.auth_company;
        const result = this.editedItem._id
          ? await putClient(this.editedItem)
          : await postClient(this.editedItem);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
    async postItem() {
      this.editedItem.company = this.$store.state.auth_company;
      try {
        const result = this.editedItem._id
          ? await putItem(this.editedItem)
          : await postItem(this.editedItem);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },

    async postCtype() {
      this.editedItem.company = this.$store.state.auth_company;
      try {
        const result = this.editedItem._id
          ? await putClothestype(this.editedItem)
          : await postClothestype(this.editedItem);
        if (result.status !== 200) throw `error : ${result.status}`;
      } catch (e) {
        alert(e);
      }
    },
    async getCompany() {
      try {
        const company = await getCompanyOneById(this.$store.state.auth_company);

        if (company.status !== 200) throw `error : ${company.status}`;
        this.company = company.data.length ? company.data[0] : {};
        this.viewTel = this.getMask(this.company.tel);
        this.viewBusinessNum = this.addAutoHyphen(this.company.business_num);
      } catch (e) {
        if (e.response.status === 404) {
          alert(`회사 정보가 없습니다. 회사 정보를 작성 후 저장해주세요!`);
          this.company.id = this.$store.state.auth_company;
        } else alert(e);
      }
    },
    async getUserList() {
      try {
        const userList = await getUserByCompany(this.$store.state.auth_company);
        if (userList.status !== 200) throw `error : ${userList.status}`;

        this.$store.commit("setUserList", userList.data);
      } catch (e) {
        if (e.response.status === 404) {
          alert(`유저 정보가 없습니다. 유저 정보를 작성 후 저장해주세요!`);
          this.userList = [];
        } else alert(e);
      }
    },

    chageUserStatus(user, value) {
      const modifyUser = this.copyData(user);
      modifyUser.signStatus = value;
      modifyUser.isActive = value === "40" ? true : false;

      this.postUser(modifyUser);
    },

    async getItemList() {
      try {
        const itemList = await getItemByCompanyP(
          this.$store.state.auth_company,
        );
        if (itemList.status !== 200) throw `error : ${itemList.status}`;

        this.itemList = itemList.data;
        this.itemList.reverse();
      } catch (e) {
        if (e.response.status === 404) {
          alert(`아이템 정보가 없습니다. 아이템 정보를 작성 후 저장해주세요!`);
          this.itemList = [];
        } else alert(e);
      }
    },

    async getCtypeList() {
      try {
        const ctypeList = await getClothestypeByCompany(
          this.$store.state.auth_company,
        );
        if (ctypeList.status !== 200) throw `error : ${ctypeList.status}`;

        this.ctypeList = ctypeList.data;
      } catch (e) {
        if (e.response.status === 404) {
          alert(`복종 정보가 없습니다. 복종 정보를 작성 후 저장해주세요!`);
          this.ctypeList = [];
        } else alert(e);
      }
    },
    getList() {
      this.$store.dispatch("SET_CLIENTLIST");
      this.$store.dispatch("SET_USE_CLIENTLIST");
      this.$store.dispatch("SET_USERLIST");
      this.$store.dispatch("SET_ITEMLIST");
      this.$store.dispatch("SET_CTYPELIST");
      this.$store.dispatch("GET_COMPANY");
      this.$store.dispatch("GET_SETTING_COMPANY");
      // this.getCompany();
    },

    // 패스워드 변경 확인
    async passwordCheck() {
      if (!this.existingPw) return;
      try {
        const result = await checkUserPw({
          uuid: this.$store.state.auth_uuid,
          password: this.existingPw,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        this.existingCheck = result.data;
        if (!this.existingCheck)
          this.existingHint =
            "현재 비밀번호가 맞지 않습니다. 다시 입력해주세요";
        else this.existingHint = "변경할 비밀번호를 입력해주세요";
      } catch (e) {
        console.log(e);
      }
    },
    // 패스워드 변경
    async modifyPassword() {
      try {
        const result = await modifyPassword({
          uuid: this.$store.state.auth_uuid,
          password: this.modifyPw,
        });

        if (result.status !== 200) throw `error: ${result.status}`;

        if (result.data) {
          alert("비밀번호가 변경되었습니다. 다시 로그인해주세요");
          this.$store.dispatch("LOGOUT");
          this.$router.push("/login");
        } else {
          alert("비밀번호가 변경되지 않았습니다. 다시 시도 해주세요");
          this.initPassword();
        }
      } catch (e) {
        console.log(e);
      }
    },

    initPassword() {
      this.existingPw = ""; // 현재 비밀번호
      this.existingCheck = false; // 비밀번호 확인
      this.modifyPw = ""; // 변경 비밀 번호
      this.checkPw = ""; // 변경 비밀번호 확인
      this.existingHint = "현재 비밀번호를 입력 후 확인해주세요";
    },
    listChange(target) {
      if (target && this.viewType !== target) this.viewType = target;
    },
    async uploadSave(kind) {
      const formLength =
        kind === "client"
          ? this.excelFormList.length
          : kind === "item"
          ? this.itemExcelFormList.length
          : this.ctypeExcelFormList.length;
      if (!formLength) {
        this.snackbar_text = `저장할 항목이 없습니다.`;
        this.snackbar = true;
        return;
      }
      if (confirm(`총 ${formLength}건을 저장 하시겠습니까?`)) {
        await this.$store.dispatch("EXCEL_UPLOAD_SAVE", kind);
      }
    },
    async removeCol(type, list) {
      const selected = [];
      list.forEach((x) => {
        if (x.checked) selected.push(x);
      });
      if (!selected.length) {
        this.snackbar_text = `삭제할 항목을 선택하세요.`;
        this.snackbar = true;
        return;
      }
      if (confirm(`${selected.length}건을 삭제하시겠습니까?`)) {
        this.$store.dispatch("SET_API_LOADING", true);
        for (let i = 0; i < selected.length; i++) {
          selected[i].status = "99";
          this.$store.commit("setEditedItemForm", selected[i]);
          type === "client"
            ? await this.$store.dispatch("SAVE_CLIENT")
            : type === "item"
            ? this.$store.dispatch("SAVE_ITEM")
            : this.$store.dispatch("SAVE_CTYPE");
          selected[i].checked = false;
        }
        await this.$store.dispatch("SET_CLIENTLIST");
        await this.$store.dispatch("SET_USE_CLIENTLIST");
        this.snackbar_text = `${selected.length}건이 삭제되었습니다.`;
        this.snackbar = true;
        this.$store.dispatch("SET_API_LOADING", false);
        this.allChecked[type] = false;
      }
    },
    allCheckItem(list, type) {
      this.allChecked[type] = !this.allChecked[type];
      list.forEach((x) => {
        if (x.status !== "99") x.checked = this.allChecked[type];
      });
    },
    checkItem(item, list, type) {
      item.checked = !item.checked;
      if (item.checked) {
        let count = 0;
        list.forEach((x) => {
          if (x.checked) count++;
        });
        if (count === list.length) this.allChecked[type] = true;
      } else {
        this.allChecked[type] = false;
      }
    },

    async getLogoImg() {
      if (this.logoImg._id) {
        try {
          const result = await downloadFile(
            this.$store.state.auth_company,
            this.logoImg._id,
          );

          if (result.status !== 200) throw `error: ${result.status}`;
          const downFile = new Blob([result.data]);

          this.preview = window.URL.createObjectURL(downFile);
        } catch (e) {
          console.log(e);
        }
      }
    },
    async setPrivew() {
      await this.$store.dispatch("GET_LOGO_ID");
      await this.getLogoImg();
    },
    async deleteAccount() {},
  },

  async created() {
    await this.setPrivew();
    this.getList();
  },
  watch: {
    someData(val) {
      this.company.tel = this.$options.filters.phoneNumber(val);
    },
  },
};
